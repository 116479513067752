// This file/class uses Babel + "ES6"

class saPlayerCore{
    get(str){
        return $(this.id + ' ' + str);
    }
    setPlayerProperty(property, value){
        this.media[property] = value;
    }
    getPlayerProperty(property){
        return this.media[property];
    }
    getPlayer(){
        return this.media;
    }
    constructor(settings){
        this.breadcrumbCategory = 'player.' + settings.type;
        this.newStream = false;
        this.liveSyncDuration = 30; // seconds
        this.liveCheckBuffer = 5; // number of seconds to use as a buffer for whether we're live or not
        this.liveCheckWindow = 20; // number of seconds allowed to get out of sync before the live indicator is removed
        this.events = {};
        this.debug = settings.debug ? true : false;
        this.skipAmount = 15;
        this.speedOptions = [0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0];
        this.currentSpeed = 1;
        this.globalShortcuts = settings.globalShortcuts;
        this.volumeIncrementPercentage = 5;
        this.sermonID = settings.id;
        this.defaultMuted = settings.muted;
        this.id = '#' + settings.id + '-' + settings.type;
        this.namespace = settings.type + '_player_' + this.id;
        this.playerContainer = this.get('');
        this.parent = this.playerContainer.parent();
        var parentId = this.parent.attr('id');
        if(parentId) this.id = '#' + parentId;
        this.moveEvent = 'mousemove.' + this.namespace + ' touchmove.' + this.namespace;
        this.dragEvent = 'mousedown.' + this.namespace + ' touchstart.' + this.namespace;
        this.exitEvent = 'mouseleave.' + this.namespace;
        this.media = this.get(settings.type)[0];
        this.autoplay = this.defaultMuted && settings.autoplay ? 'muted' : settings.autoplay;
        this.duration = settings.duration;
        this.webcast = settings.webcast;
        this.webcast_archive = settings.webcast_archive;
        this.broadcasterOnline = this.webcast;
        this.utils = sa.utils.players;
        this.type = settings.type;
        this.broadcaster_id = settings.broadcaster_id;
        this.webcastStartTimestamp = settings.webcastStartTimestamp;
        this.canAutoplayOptions = {
            timeout: 2000,
            inline: true,
            muted: !this.defaultMuted ? false : true,
        };
        this.playerContainer.attr('tabindex', sa.players.length);

        if(settings.webcast && settings.canWebcast && !settings.skipWebcastCheck){
            this.setupWebcast();
        }
        this.webcastID = settings.webcastID;
        this.waveform = settings.wave;

        var validStartTime = settings.startTime && settings.startTime < this.duration;
        this.startTime = validStartTime ? settings.startTime : 0;
        this.get('.current.time span').html(sa.toHHMMSS(this.startTime));
        var validEndTime = (settings.endTime && settings.endTime < this.duration) && (!this.startTime || settings.endTime > this.startTime);
        this.endTime = validEndTime ? settings.endTime : undefined;

        if(this.webcast){
            var src = $(this.media).attr('src');
            if(src.indexOf("?preview=true") != -1){
                var newUrl = src.replace('?preview=true', '').replace('/webcasts/', '/webcasts_preview/');
                $(this.media).attr('src', newUrl);
            }
        }

        $(window).resize(() => this.resize());
        if(sa.mobile) this.get('.remove-on-mobile').remove();
    }
    resize(){}
    isPlaying(){
        return !this.getPlayerProperty('paused');
    }
    isPaused(){
        return this.getPlayerProperty('paused');
    }
    play(onSuccess){
        if(this.chromecasting) return;
        // temporary logging to help figure out a sentry error
        var stack = new Error().stack;
        sa.sentry.breadcrumb('Play Attempt', Sentry.Severity.Info, this.breadcrumbCategory, stack);
        var play = this.getPlayer().play();
        if (play) {
            play.then((data, error) => {
                sa.sentry.breadcrumb('Play Promise Success', Sentry.Severity.Info, this.breadcrumbCategory);
                if(onSuccess){
                    onSuccess();
                }
            }).catch((error) => {
                this.playerContainer.removeClass('waiting playing').addClass('unplayed');
                sa.sentry.breadcrumb('Play Promise Failure', Sentry.Severity.Info, this.breadcrumbCategory, error);
            });
        }
    }
    checkAutoplay(config){
        if(!this.autoplay){
            if(config.any) config.any();
            return;
        }
        sa.sentry.breadcrumb('Start autoplay check.', Sentry.Severity.Info, this.breadcrumbCategory);
        var a = canAutoplay[this.type](this.canAutoplayOptions);
        if(!a){
            if(config.any) config.any();
            if(config.failure) config.failure();
            console.warn('Autoplay unavailable');
        } else {
            a.then((data, error) => {
                if (!data.result) {
                    // Can not autoplay
                    console.warn('Autoplay unavailable');
                    sa.sentry.breadcrumb('Failed autoplay check.', Sentry.Severity.Warning, this.breadcrumbCategory, data.error);
                    if(config.failure) config.failure();
                    if(config.any) config.any();

                } else {
                    sa.sentry.breadcrumb('Succeeded autoplay check.', Sentry.Severity.Info, this.breadcrumbCategory, data);
                    config.success();
                    if(config.any) config.any();
                }
            }).catch((error) => {
                console.warn('Autoplay unavailable');
                if(config.any) config.any();
                if(config.failure) config.failure();
                sa.sentry.breadcrumb('Caught Autoplay check exception.', Sentry.Severity.Warning, this.breadcrumbCategory, error);
            });
        }
    }
    pause(){
        if(!this.getPlayer()) return;
        sa.sentry.breadcrumb('Pause', Sentry.Severity.Info, this.breadcrumbCategory);
        this.getPlayer().pause();
    }
    ended(){
        sa.sentry.breadcrumb('Media Ended', Sentry.Severity.Info, this.breadcrumbCategory);
        this.playerContainer.addClass('ended');
        if(this.isLiveStream()) return;
        setTimeout(() => {
            this.pause();
            this.playerContainer.removeClass('playing').addClass('paused');
        }, 0);
        this.get('.share-modal').addClass('show');
        if(!this.casting) return;
        this.cast.stopCasting();
    }
    togglePlay(){
        if(!this.isPlaying()){
            this.play();
        } else {
            this.pause();
        }
        if(!this.casting) return;
        this.cast.togglePlay();
    }
    updateTime(){
        if(this.currentTime() == undefined){
            sa.sentry.breadcrumb('Time is undefined!', Sentry.Severity.Warning, this.breadcrumbCategory);
            return;
        }
        this.updateTimeAndDuration();
    }
    setupWebcast(){
        sa.webcasts.interval = 8;
        sa.webcasts.subscribeToWebcastCheck(() => this.checkWebcastEnd() );
    }
    webcastStatusChange(){
        this.get('.webcast-new-stream-notification').toggleClass('show', this.newStream);
        this.get('.webcast-back-online-notification').toggleClass('show', this.broadcasterOnline && this.playerContainer.hasClass('offline') && !this.newStream);
        this.get('.webcast-gone-offline-notification').toggleClass('show', !this.broadcasterOnline);
        if(this.broadcasterOnline && !this.newStream){
            this.playerContainer.removeClass('ended');
        }
        this.playerContainer.toggleClass('offline', !this.broadcasterOnline);
        if(!this.broadcasterOnline && !this.newStream){
            this.updateLiveDuration();
        }
    }
    checkWebcastEnd(){
        if(this.newStream) return;
        var webcast = sa.webcasts.getWebcastsInProgressBySource(this.broadcaster_id);
        var previousStatus = this.broadcasterOnline;
        this.broadcasterOnline = webcast ? true : false;
        if(previousStatus != this.broadcasterOnline){
            this.webcastStatusChange();
        }
        if(!webcast) return;
        var total = webcast.total_count;
        this.get('.viewer-count span').text(total);
        this.totalCount = total;

        this.newStream = this.webcastID != webcast.webcast_id;
        if(this.newStream){
            this.webcastStatusChange();
        }
    }
    liveStreamEnded(){
        return (this.isLiveStream() && !this.broadcasterOnline) || this.newStream;
    }
    isLiveStream(){
        return this.webcast && !this.webcast_archive ? true : false;
    }
    liveDurationFromTimestamp(){
        var timestamp = this.webcastStartTimestamp ? ($.now() / 1000) - this.webcastStartTimestamp : this.duration;
        return timestamp - this.liveSyncDuration;
    }
    updateLiveDuration(){
        if(this.newStream) return;
        var mediaDuration = this.getPlayer().duration;
        if(!this.liveStreamEnded()){
            if(mediaDuration && sa.validNumber(mediaDuration)){
                var different = this.mediaDuration ? Math.abs(mediaDuration - this.mediaDuration) > 1 : true;
                if(mediaDuration && different){
                    // update media duration
                    this.mediaDuration = mediaDuration;
                    this.mediaDurationUpdateTime = $.now();
                }
                var timeSinceMediaDurationUpdate = ($.now() - this.mediaDurationUpdateTime) / 1000;
                this.duration = this.mediaDuration + timeSinceMediaDurationUpdate;
            } else if(this.webcastStartTimestamp && this.broadcasterOnline){
                this.duration = this.liveDurationFromTimestamp();
            }
        } else {
            this.duration = mediaDuration;
            this.mediaDurationUpdateTime = $.now();
            this.playerContainer.removeClass('live');
        }
        if(!this.duration) return;
        this.get('.duration span').html(sa.toHHMMSS(this.duration));
        this.updateLiveIndicator();
    }
    onCastSetup(){}
    updateLiveIndicator(){
        var current = this.currentTime();
        var buffers = this.currentLiveBuffers();
        if(current >= buffers.setOnlineOver){
            this.playerContainer.addClass('live');
        } else if (current < buffers.setOfflineUnder){
            this.playerContainer.removeClass('live');
        }
        if(this.currentTimeIsLive()){
            this.get('.webcast-back-online-notification').removeClass('show');
        }
    }
    currentLiveBuffers(){
        var goal = this.goalLiveTime();
        return {
            setOnlineOver: goal - this.liveCheckBuffer,
            setOfflineUnder: goal - this.liveCheckWindow,
        }
    }
    goalLiveTime(){
        return this.duration - this.liveSyncDuration;
    }
    currentTimeIsLive(){
        return this.playerContainer.hasClass('live') && !this.liveStreamEnded();
    }
    reloadPlayer(){
        location.reload();
    }
    goToLive(){
        this.get('.webcast-back-online-notification').removeClass('show');
        if(this.chromecasting){
            this.cast.goToLive();
            return;
        }
        if(!this.hasPlayed) return;
        if(this.currentTimeIsLive() && this.isPlaying()) return;
        this.currentTime(this.goalLiveTime() - 1);
        this.play();
    }
    updateTimeAndDuration(){
        var time = this.currentTime();
        this.playerContainer.toggleClass('webcast', this.isLiveStream() && !this.liveStreamEnded());
        this.playerContainer.toggleClass('archive', !this.isLiveStream() || this.liveStreamEnded());
        if(this.isLiveStream() && !this.liveStreamEnded()){
            this.updateLiveDuration();
        }

        this.get('.current.time span').html(sa.toHHMMSS(time));
        if(this.endTime && time >= this.endTime){
            this.ended();
        }

        var completionPercentage = (time/this.duration) * 100;
        this.get('.sa_wave_mask.wave_played').css('width', completionPercentage + '%');

        this.updatePopoutUrl();
    }
    updateBuffer(){
        if(this.chromecasting) return;
        var buffered = this.getPlayerProperty('buffered');
        if(!buffered.length) return;
        var endTime = 0;
        for(var i = 0; i < buffered.length; i++){
            if(buffered.end(i) > endTime){
                endTime = buffered.end(i);
            }
        }
        var percentage = this.utils.timeToPercent(endTime, this.duration);
        this.get('.wave_buffered').css('width', percentage + '%');
        this.updateTimeAndDuration();
    }
    setupControls(){
        this.get('.control.play').saClick(() => this.togglePlay(), '', false, true);
        this.get('.control.speed').saClick(() => this.cycleSpeed(), '', false, true);
        this.get('.control.skip-forward').saClick(() => this.skipForward(), '', false, true);
        this.get('.control.skip-backward').saClick(() => this.skipBackward(), '', false, true);
        this.get('.control.mute').saClick(() => this.toggleMute(), '', false, true);
        this.get('.current.time span').saClick(() => this.showTimeInput());
        this.get('.current.time input').keysEvent([sa.keycodes.enter], 'keydown', () => this.setTimeInput());
        this.get('.current.time input').keysEvent([sa.keycodes.esc, sa.keycodes.tab], 'keydown', () => this.hideTimeInput());
        this.get('.goToLive').saClick(() => this.goToLive(), '', false, true);
        this.get('.reloadWebcast').saClick(() => this.reloadPlayer(), '', false, true);
        this.setupVolume();
    }
    shortcutTargets(){
        if(this.globalShortcuts) return $('*');
        return this.playerContainer.add(this.get('*'));
    }
    closeShortcutHandler(){
        var shareModal = this.get('.share-modal');
        if (shareModal.hasClass('show')){
            shareModal.find('.dismiss').trigger('click');
            return;
        }
    }
    shortcutList(){
        // array of possible shortcuts that can be subscribed to
        var k = sa.keycodes;
        return [
            { title: 'play',            keys: [k.space, k.k], preventDefault: true },
            { title: 'fullscreen',      keys: [k.f] },
            { title: 'mute',            keys: [k.m] },
            { title: 'volumeIncrease',  keys: [k.arrowUp], preventDefault: true },
            { title: 'volumeDecrease',  keys: [k.arrowDown], preventDefault: true },
            { title: 'skipBackward',    keys: [k.arrowLeft, k.j] },
            { title: 'skipForward',     keys: [k.arrowRight, k.l] },
            { title: 'speedIncrease',   keys: [k.period], addedCheck: k.shift },
            { title: 'speedDecrease',   keys: [k.comma], addedCheck: k.shift },
            { title: 'close',           keys: [k.esc] },
            { title: 'shortcutList',    keys: [k.questionMark], addedCheck: k.shift },
        ];
    }
    setupShortcuts(){
        var targets = this.shortcutTargets();
        var config = {
            fullscreen: this.toggleFullscreen ? () => {
                this.toggleFullscreen();
            } : undefined,
            mute: () => this.toggleMute(),
            skipBackward: () => this.skipBackward(),
            skipForward: () => this.skipForward(),
            speedIncrease: () => this.increaseSpeed(),
            speedDecrease: () => this.decreaseSpeed(),
            play: () => this.togglePlay(),
            volumeIncrease: () => this.increaseVolume(),
            volumeDecrease: () => this.decreaseVolume(),
            close: () => this.closeShortcutHandler(),
            shortcutList: this.toggleShortcutList ? (event) => {
                this.toggleShortcutList(event);
            } : undefined,
            focused: () => {
                return this.inFocus();
            },
        };

        var individualShortcut = (o) => {
            var allowDefault = o.preventDefault ? false : true;
            targets.keysEvent(o.shortcuts, 'keydown', (event) => {
                if(!config.focused()) return;
                if(o.addedCheck && !o.addedCheck(event)) return;
                o.onShortcut(event);
            }, allowDefault, true);
        };

        var shortcutList = this.shortcutList();
        for(var i = 0; i < shortcutList.length; i++){
            var shortcut = shortcutList[i];
            var onShortcut = config[shortcut.title];
            if(!onShortcut) continue;
            var individualConfig = {
                onShortcut:     onShortcut,
                preventDefault: shortcut.preventDefault,
                shortcuts:      shortcut.keys,
                addedCheck:     shortcut.addedCheck,
            };
            individualShortcut(individualConfig);
        }
    }
    canPlay(){
        return this.media.readyState >= 4;
    }
    get hasPlayed(){
        return !this.playerContainer.hasClass('unplayed');
    }
    isWaiting(){
        return this.playerContainer.hasClass('waiting');
    }
    skipForward(){
        var end = this.endTime ? this.endTime : this.duration;
        this.currentTime((this.currentTime() + this.skipAmount).clamp(this.startTime, end));
    }
    skipBackward(){
        var end = this.endTime ? this.endTime : this.duration;
        this.currentTime((this.currentTime() - this.skipAmount).clamp(this.startTime, end));
        this.playerContainer.removeClass('live');
    }
    isAtMaxSpeed(){
        return this.currentSpeed == this.speedOptions.length - 1;
    }
    isAtMinSpeed(){
        return !this.currentSpeed;
    }
    cycleSpeed(){
        this.currentSpeed = this.isAtMaxSpeed() ? 0 : this.currentSpeed + 1;
        this.setPlaybackSpeed();
    }
    increaseSpeed(){
        if(this.isAtMaxSpeed()) return;
        this.currentSpeed += 1;
        this.setPlaybackSpeed();
    }
    decreaseSpeed(){
        if(this.isAtMinSpeed()) return;
        this.currentSpeed -= 1;
        this.setPlaybackSpeed();
    }
    setPlaybackSpeed(){
        var speed = this.speedOptions[this.currentSpeed];
        this.setPlayerProperty('playbackRate', speed);
        this.get('.control.speed .icon').removeClass('speed-1 speed-2 speed-3 speed-4 speed-5 speed-6 speed-7');
        this.get('.control.speed .icon').addClass('speed-' + (this.currentSpeed + 1));
    }
    setupVolume(){
        if(sa.mobile) return;

        this.muteIcon = this.get('.control.mute .icon');
        this.volumeSlider = this.get('div.volume-slider');
        this.volumeSlider.on(this.dragEvent, () => {
            this.utils.bindDragEvent({
                element: this.get('.volume-slider-container'),
                container: this.get('.video-player-controls'),
                moveFunction: (event) => {
                    this.changeVolume(event);
                },
                namespace: this.namespace,
            });
        }).on('click.' + this.namespace, (event) => {
            this.changeVolume(event);
        });
    }
    setInitialVolume(){
        if(sa.mobile) return;

        var vol = this.getVolumeCookie();
        if(vol){
            this.setVolume(vol);
        }

        if(this.getMutedCookie()){
            this.mute();
        } else if (this.defaultMuted){
            this.mute(true);
        }
    }
    isMuted(){
        return this.getPlayerProperty('muted');
    }
    mute(skipCookies){
        this.muteIcon.addClass('muted');
        this.setPlayerProperty('muted', true);
        if(!skipCookies){
            this.setMutedCookie(true);
        }
        this.get('.volume-slider-fill-container').css('width', 0);
    }
    unmute(){
        this.muteIcon.removeClass('muted');
        this.setPlayerProperty('muted', false);
        this.setMutedCookie(false);
        var volume = this.volumeSlider.data('volume');
        this.get('.volume-slider-fill-container').css('width', volume + '%');
    }
    toggleMute(){
        if(!this.isMuted()){
            this.mute();
        } else {
            this.unmute();
        }
        if(!this.casting) return;
        this.cast.toggleMute();
    }
    decreaseVolume(){
        if(this.getVolumePercentage() <= 0) return;
        var newV = this.getVolumePercentage() - this.volumeIncrementPercentage;
        this.setVolume(newV);
    }
    increaseVolume(){
        if(this.getVolumePercentage() >= 100) return;
        var newV = this.getVolumePercentage() + this.volumeIncrementPercentage;
        this.setVolume(newV);
    }
    getVolumePercentage(){
        return this.getVolume() * 100;
    }
    getVolume(){
        return this.getPlayerProperty('volume');
    }
    changeVolume(event){
        var sliderContainer = this.get('.volume-slider-container');
        var percent = this.utils.mousePercentage(event, sliderContainer);
        this.setVolume(percent);
    }
    setVolume(percent){
        percent = percent.clamp(0, 100);
        if(percent <= 0){
            if(!this.isMuted()){
                this.toggleMute();
            }
        } else {
            if (this.isMuted()){
                this.toggleMute();
            }
            this.setVolumeCookie(percent);

            this.volumeSlider.data('volume', percent);
            this.get('.volume-slider-fill-container').css('width', percent + '%');
            percent = percent/100;
            this.setPlayerProperty('volume', percent);
            if(!this.casting) return;
            this.cast.setVolume(percent);
        }
    }
    getVolumeCookie(){
        var volume = Cookies.get(this.type + '_volume');
        return volume != undefined ? parseFloat(volume) : false;
    }
    setVolumeCookie(percent){
        Cookies.set(this.type + '_volume', percent);
    }
    getMutedCookie(){
        return Cookies.get(this.type + '_muted') == 'true';
    }
    setMutedCookie(bool){
        Cookies.set(this.type + '_muted', bool);
    }
    currentTime(time){
        if(time != undefined){
            if(!sa.validNumber(time)) return;
            this.setPlayerProperty('currentTime', time);
            this.updateTime();
            if(!this.casting) return;
            this.cast.setCurrentTime(time);
        } else {
            return this.getPlayerProperty('currentTime');
        }
    }
    remove(){
        if(!this.waveform) return;
        this.waveform.unbindResizeEvent();
    }
    eventFullscreenChange(){
        if(!this.waveform) return;
        this.waveform.resize();
    }
    eventWaiting(){
        setTimeout(() =>{
            sa.sentry.breadcrumb('Waiting Event', Sentry.Severity.Info, this.breadcrumbCategory);
            this.playerContainer.addClass('waiting loading').removeClass('unplayed');
        }, 50);
    }
    eventCanPlay(){
        this.setStartTime()
    }
    eventPlaying(){
        this.playerContainer.removeClass('ended waiting loading paused autoplaying').addClass('playing');
    }
    eventPaused(){
        this.playerContainer.addClass('paused').removeClass('ended waiting loading playing autoplaying');
    }
    eventTimeUpdate(){
        if(!this.canPlay() || (!this.isPlaying() && !this.hasPlayed)) return;
        this.playerContainer.removeClass('waiting loading unplayed');
        if(!this.isPaused()){
            this.playerContainer.removeClass('paused');
        }
        this.updateTime();
    }
    eventLoadedMetadata(){
        if(!sa.validNumber(this.media.duration)) return;
        this.duration = this.media.duration;
        this.setDuration();
    }
    eventProgress(){
        this.updateBuffer();
    }
    setupEvents(){
        this.onEvent('waiting', () => {
            this.eventWaiting();
        });
        this.onEvent('playing', () => {
            this.eventPlaying();
        });
        this.onEvent('canplay', () => {
            this.eventCanPlay();
        });
        this.onEvent('pause', (event) => {
            this.eventPaused();
        });
        this.onEvent('timeupdate', () => {
            this.eventTimeUpdate();
        });
        this.onEvent('durationchange', () => {
            this.updateTime();
        });
        this.onEvent('ended', () => {
            this.ended();
        });
        this.onEvent('progress', () => {
            this.eventProgress();
        });
        this.onEvent('loadedmetadata', () => {
            this.eventLoadedMetadata();
        });
        $(document).on('webkitfullscreenchange mozfullscreenchange fullscreenchange MSFullscreenChange webkitbeginfullscreen webkitendfullscreen', () => {
            this.eventFullscreenChange();
        });
        this.setupWaveformEvents();
    }
    waveformMouseUp(event){
        event.stopImmediatePropagation();
        this.waveformMouseMove(event);
        if(this.isPaused()) this.play();
        this.updateScrubbingWaveformVisibility();
        sa.sentry.breadcrumb('Stop scrubbing.', Sentry.Severity.Info, this.breadcrumbCategory);
    }
    waveformMouseMove(event){
        event.stopImmediatePropagation();
        if(this.isPlaying() || this.casting){
            this.playerContainer.addClass('scrubbing');
            if(this.isDragging()){
                var time = this.getMouseTime(event);
                this.currentTime(time);
            }
        }
        this.updateScrubbingWaveformVisibility();
        this.moveScrubbingWaveform(event);
    }
    waveformMouseExit(event){
        this.playerContainer.removeClass('scrubbing');
        this.updateScrubbingWaveformVisibility();
    }
    waveformMouseEnter(event){
        // only used by video player
    }
    isScrubbing(){
        return this.playerContainer.hasClass('scrubbing');
    }
    isDragging(){
        // we don't care if they are hovering over the waveform on mobile
        if(sa.mobile) return true;
        return this.get('.sa_wave-container').data('dragging');
    }
    updateScrubbingWaveformVisibility(){
        if(this.isScrubbing()){
            this.get('.wave_scrub').addClass('show');
        } else {
            this.get('.wave_scrub').removeClass('show');
        }
    }
    moveScrubbingWaveform(event){
        this.get('.sa_wave_mask.wave_scrub').css('width', this.getMousePosition(event) + 'px');
    }
    getMousePosition(event){
        var canvasOffset = this.get('.sa_wave-container').offset();
        return parseInt(sa.getPointerEvent(event).pageX - canvasOffset.left);
    }
    getMouseTime(event){
        var canvasWidth = this.get('.sa_wave-container').width();
        return parseInt((this.getMousePosition(event) / canvasWidth) * this.duration);
    }
    setupWaveformEvents(){
        var wave = this.get('.sa_wave-container');
        wave.on( "touchend touchcancel mousemove",(event) => this.waveformMouseMove(event));
        wave.on( "mouseleave",(event) => this.waveformMouseExit(event));
        wave.on( "mouseenter", (event) => this.waveformMouseEnter(event));
        wave.on(this.dragEvent, () => {
            this.utils.bindDragEvent({
                element: wave,
                container: this.playerContainer,
                moveFunction: (event) => this.waveformMouseMove(event),
                namespace: this.namespace,
                upFunction: (event) => this.waveformMouseUp(event),
                exitFunction: (event) => this.waveformMouseExit(event),
            });
        });
    }
    onEvent(event, func){
        if(!this.events[event]){
            this.events[event] = {
                onEvent: [],
            };
        }
        this.events[event].onEvent.push(func);
        if(!this.events[event].init){
            var checkEvents = (e) => {
                for(var i = 0; i < this.events[event].onEvent.length; i++){
                    this.events[event].onEvent[i](e);
                }
            };
            var p = this.getPlayer();
            if(p.on){
                this.getPlayer().on(event, checkEvents);
            } else {
                this.getPlayer().addEventListener(event, checkEvents);
            }
            this.events[event].init = true;
        }
    }
    inFocus(){
        if(this.globalShortcuts) return this.inGlobalFocus ? true : false;
        var list = this.playerContainer.add(this.get('*'));
        return list.find(document.activeElement).length > 0 || this.playerContainer.is($(document.activeElement));
    }
    setGlobalFocus(focus){
        this.inGlobalFocus = focus ? true : false;
    }
    updatePopoutUrl(){
        if(this.isLiveStream()) return;
        if(!this.popoutElement){
            this.popoutElement = this.get('.popout-icon');
            var href = this.popoutElement.attr('href');
            if(!href) return;
            this.popoutBaseUrl = href.indexOf('?') != -1 ? href + '&' : href + '?';
        }
        if(!this.popoutElement.length) return;
        // TODO: once we use our own popouts, switch this to "time"
        this.popoutElement.attr('href', this.popoutBaseUrl + 't=' + parseInt(this.currentTime()));
    }
    setDuration(){
        var duration = this.isLiveStream() ? this.liveDurationFromTimestamp() : Math.round(this.duration);
        this.get('.duration span').html(sa.toHHMMSS(duration));
    }
    setStartTime(){
        if(this.startTimeSet) return;
        this.startTimeSet = true;
        if(!this.startTime || this.startTime >= this.duration) return;
        this.currentTime(this.startTime);
    }
    isTimeInputOpen(){
        return this.get('.current.time').hasClass('input');
    }
    showTimeInput(){
        var input = this.get('.current.time input');
        input.val('').prop('placeholder', sa.toHHMMSS(this.currentTime()));
        this.get('.current.time').addClass('input');
        setTimeout(() => {
            input.focus();
            input.offClick(() => this.hideTimeInput());
        }, 0);
    }
    hideTimeInput(){
        this.get('.current.time').removeClass('input');
        this.playerContainer.focus();
        sa.offClick.clear();
    }
    setTimeInput(){
        this.currentTime(this.parseTypedTime());
        this.hideTimeInput();
    }
    parseTypedTime(){
        // this parsing function could use improvement
        var result = this.get('.current.time input').val();
        var plus = result.indexOf('+') != -1;
        var minus = result.indexOf('-') != -1;
        var formatted = result.indexOf(':') != -1;
        var noInput = result == '';
        var c = parseInt(this.currentTime());
        if(formatted){ // 00:00
            var f = result.split(':');
            var r = (parseInt(f[0]) * 60) + parseInt(f[1]);
            result = r;
        } else if (plus){ // +10
            result = parseInt(result) + this.currentTime();
        } else if (minus){ // -10
            result = parseInt(result) + this.currentTime();
        } else if (noInput){
            return null;
        } else { // 149
            result = parseInt(result);
        }
        if(sa.validNumber(result) && result > 0 && result < this.duration){
            return result;
        } else {
            if(minus){
                return 0;
            } else {
                return c;
            }
        }
    }
    toReadableBitrate(bitrate){
        var kbps = bitrate / 1024;
        if(kbps < 1024) return sa.toDecimals(kbps, 0) + ' kbps';
        return sa.toDecimals(kbps/1024, 1) + ' Mbps';
    }
    castStatusChanged(deviceName){
        // on chromecast this is either false or the device name
        // on airplay this is either true or false
        if(this.casting && deviceName && this.chromecasting) return;
        this.playerContainer.toggleClass('casting', deviceName);
        this.playerContainer.toggleClass('airplaying', this.airplaying);
        this.playerContainer.toggleClass('chromecasting', this.chromecasting);
        var notification = deviceName && !this.airplaying;
        this.get('.casting-notification').toggleClass('show', notification);
        if(deviceName){
            // TODO: remove this override once archive video stats are in place
            if(this.initArchive && !this.archiveInit && !this.webcast && !this.airplaying){
                this.initArchive();
            }
            if(this.chromecasting){
                this.pause();
                this.get('.casting-notification .device-name').text(deviceName);
            } else if (this.airplaying){
                this.play();
            }
            setTimeout(() => {
                this.playerContainer.removeClass('unplayed paused').addClass('playing');
            }, 1);
        } else if(!this.isLiveStream()) {
            this.pause();
            this.playerContainer.removeClass('playing').addClass('paused');
        }
    }
    get casting() {
        return this.playerContainer.hasClass('casting');
    }
    get chromecasting(){
        return this.casting && this.cast.isChromecasting;
    }
    get airplaying(){
        return this.casting && this.cast.isAirplaying;
    }
}
