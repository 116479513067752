// This file/class uses Babel + "ES6"

class saVideoPlayer extends saPlayerCore {
    constructor(settings) {
        settings.type = 'video';
        super(settings);
        this.animationFrame = sa.requestAnimationFrame.bind(window);
        this.loop = settings.loop;
        this.test = settings.test;
        this.defaultQuality = settings.quality;
        this.audioOnly = settings.audioOnly;
        if(this.audioOnly){
            this.audioMode = true;
        }
        this.m3u8 = this.get('video').attr('src');
        this.audiom3u8 = settings.webcastAudio;
        this.mouseRestThreshold = 3000; //ms the mouse has to be still before controls are closed

        this.setupLanguageSelector();
        this.setDuration();
    }
    resize(){
        this.setSettingsMaxHeight();
    }
    setupEvents(){
        super.setupEvents();

        this.onEvent('waiting', () => {
            this.eventWaiting();
        });
        this.onEvent('loadeddata', () => {
            this.eventLoadedData();
        });
    }
    eventLoadedData(){
        if(!this.qualitySwitchingCallback) return;
        this.qualitySwitchingCallback();
        this.qualitySwitchingCallback = undefined;
    }
    webcastStatusChange(){
        super.webcastStatusChange();
        if(this.hls && this.broadcasterOnline && !this.newStream){
            var playing = this.isPlaying();
            var currentTime = this.currentTime();
            this.playerContainer.removeClass('playing').addClass('paused waiting');
            this.hls.destroy();
            this.initializeHLS();
            this.currentTime(currentTime);
            if(playing){
                setTimeout(() => {
                    this.play();
                }, 1);
            }
        }
    }
    eventLoadedMetadata(){
        if(!this.webcast){
            super.eventLoadedMetadata();
        }
    }
    // TODO: remove this override once archive video stats are in place
    togglePlay(){
        if(!this.archiveInit && !this.webcast){
            this.initArchive();
        }
        super.togglePlay();
    }
    // TODO: remove this override once archive video stats are in place
    initArchive () {
        this.archiveInit = true;
        this.initializeHLS();
        this.playerContainer.removeClass('unplayed').addClass('waiting');
    }
    setupLanguageSelector(){
        var lis = this.get('.translation-dropdown li');
        lis.saClick(function(){
            sa.i18n.setLanguage($(this).data('language'), true);
        });
    }
    setupControls(){
        super.setupControls();

        this.get('.big-play').saClick((event, element) => {
            var target = $(event.target);
            if(target.hasClass('cast')) return;
            if(target.parent().hasClass('cast')) return;
            this.togglePlay();
        }, '', false, true);
        this.get('.control.fullscreen').saClick(() => this.toggleFullscreen(), '', false, true);
        this.get('.control.media-settings').saClick(() => this.toggleSettings(), '', false, true);
        this.get('.menu-settings .close').saClick(() => this.closeSettings(), '', false, true);
        this.get('.video-player-controls *').saClick((event) => this.setLastMouseMovement(event), '', false, true);
        this.get('[data-menu-toggle]').each((index, element) => {
            var target = $('.' + $(element).data('menu-toggle'));
            target.on('updateHeight', () => {
                var height = target.getRealDimensions().outerHeight;
                target.data('height', height);
            });
            target.trigger('updateHeight');
        }).saClick((event, element) => {
            this.switchSettingsMenus('.' + $(element).data('menu-toggle'));
        });
        this.playerContainer.hover(
            (event) => {
                this.mouseEnterPlayer(event);
            }, (event) => {
                this.mouseExitPlayer(event);
            }
        ).on(this.moveEvent, (event) => {
            this.mouseMove(event);
        });
    }
    onCastSetup(){
        this.initializeImmediately();
    }
    initializeImmediately(){
        sa.sentry.breadcrumb('Initialize Player', Sentry.Severity.Info, this.breadcrumbCategory);
        this.setupControls();
        this.setupMouseRestPoller();
        // TODO: remove this webcast and autoplay check once archive video stats are in place
        // this.initializeHLS();

        if(this.webcast){
            this.initializeHLS();
        } else{
            var setInitShortcut = () => {
                this.shortcutTargets().keysEvent([sa.keycodes.space, sa.keycodes.k], 'keydown.initArchiveShortcut', () => {
                    if(!this.inFocus()) return;
                    this.togglePlay();
                    this.shortcutTargets().off('.initArchiveShortcut');
                }, false, true);
            }
            if(this.autoplay){
                this.checkAutoplay({
                    success: () => {
                        this.initArchive();
                    },
                    failure: () => {
                        this.get('').removeClass('autoplaying playing').addClass('archive unplayed');
                        setInitShortcut();
                    },
                });
            } else {
                setInitShortcut();
            }
        }
    }
    initializeHLS(source){
        sa.sentry.breadcrumb('Initialize player with hls', Sentry.Severity.Info, this.breadcrumbCategory);
        var nativeHls = this.media.canPlayType('application/vnd.apple.mpegurl');
        var canAirPlay = nativeHls && this.cast && this.cast.canAirPlay;
        if(Hls.isSupported() && !canAirPlay) {
            // https://github.com/video-dev/hls.js/blob/master/docs/API.md#fine-tuning
            var config = {
                // autoStartLoad: true,
                // startPosition: -1,
                // capLevelOnFPSDrop: false,
                // capLevelToPlayerSize: false,
                // debug: false,
                // defaultAudioCodec: undefined,
                // initialLiveManifestSize: 1,
                // maxBufferLength: 30,
                // maxMaxBufferLength: 600,
                // maxBufferSize: 60*1000*1000,
                // maxBufferHole: 0.5,
                // lowBufferWatchdogPeriod: 0.5,
                // highBufferWatchdogPeriod: 3,
                // nudgeOffset: 0.1,
                // nudgeMaxRetry: 3,
                // maxFragLookUpTolerance: 0.25,
                // liveSyncDurationCount: 3,
                // liveSyncDuration: undefined,
                // liveMaxLatencyDuration: 30,
                // liveMaxLatencyDurationCount: Infinity,
                // enableWorker: true,
                // enableSoftwareAES: true,
                // manifestLoadingTimeOut: 10000,
                // manifestLoadingMaxRetry: 1,
                // manifestLoadingRetryDelay: 1000,
                // manifestLoadingMaxRetryTimeout: 64000,
                // startLevel: undefined,
                // levelLoadingTimeOut: 10000,
                // levelLoadingMaxRetry: 4,
                // levelLoadingRetryDelay: 1000,
                // levelLoadingMaxRetryTimeout: 64000,
                // fragLoadingTimeOut: 20000,
                // fragLoadingMaxRetry: 6,
                // fragLoadingRetryDelay: 1000,
                // fragLoadingMaxRetryTimeout: 64000,
                // startFragPrefetch: false,
                // fpsDroppedMonitoringPeriod: 5000,
                // fpsDroppedMonitoringThreshold: 0.2,
                // appendErrorMaxRetry: 3,
                // loader: customLoader,
                // fLoader: customFragmentLoader,
                // pLoader: customPlaylistLoader,
                // xhrSetup: XMLHttpRequestSetupCallback,
                // fetchSetup: FetchSetupCallback,
                // abrController: AbrController,
                // bufferController: BufferController,
                // capLevelController: CapLevelController,
                // fpsController: FPSController,
                // timelineController: TimelineController,
                // enableWebVTT: true,
                // enableCEA708Captions: true,
                // stretchShortVideoTrack: false,
                // maxAudioFramesDrift: 1,
                // forceKeyFrameOnDiscontinuity: true,
                // abrEwmaFastLive: 3.0,
                // abrEwmaSlowLive: 9.0,
                // abrEwmaFastVoD: 3.0,
                // abrEwmaSlowVoD: 9.0,
                // abrEwmaDefaultEstimate: 500000,
                // abrBandWidthFactor: 0.95,
                // abrBandWidthUpFactor: 0.7,
                // abrMaxWithRealBitrate: false,
                // maxStarvationDelay: 4,
                // maxLoadingDelay: 4,
                // minAutoBitrate: 0,
                // emeEnabled: false,
                // widevineLicenseUrl: undefined,
                // requestMediaKeySystemAccessFunc: requestMediaKeySystemAccess,
                enableCEA708Captions: false,
                enableWebVTT: false,
                debug: this.debug,
                liveSyncDuration: this.liveSyncDuration,
                maxBufferLength: 60,
                manifestLoadingTimeOut: 15000,
            };

            this.hls = new Hls(config);
            this.hls.loadSource(source ? source : this.m3u8);
            this.hls.attachMedia(this.media);
            this.hls.on(Hls.Events.MANIFEST_PARSED,() => {
                clearTimeout(this.manifestLoadingTimeOut);
                if(this.qualities){
                    this.setupHlsQualities();
                } else {
                    this.videoInitialized();
                }
            });
            this.hls.on(Hls.Events.ERROR, (event, data) => {
                switch(data.details){
                    case Hls.ErrorDetails.BUFFER_STALLED_ERROR:
                    case Hls.ErrorDetails.BUFFER_SEEK_OVER_HOLE:
                    case Hls.ErrorDetails.BUFFER_NUDGE_ON_STALL:
                        if(!this.webcast) break;
                        var url = 'https://webcast-cloud.sermonaudio.com/report_error/' + this.webcastID + '/' + data.details + '/';
                        $.ajax({
                            url: url,
                            data: {
                                fatal: data.fatal,
                                buffer: data.buffer,
                                duration: this.duration,
                                playTime: this.currentTime(),
                            },
                            success: (d) => {
                                console.warn('Successfully reported error', d);
                            },
                            error: (e) => {
                                console.error('Failed to report error', e);
                            },
                        });
                        break;
                    default:
                        break;
                }
                if (data.fatal) {
                    console.error(event, data);
                    switch(data.type) {
                        case Hls.ErrorTypes.NETWORK_ERROR:
                            // try to recover network error
                            sa.sentry.breadcrumb("Fatal network error encountered, trying to recover.", Sentry.Severity.Error, this.breadcrumbCategory, data);
                            this.hls.startLoad();
                            break;
                        case Hls.ErrorTypes.MEDIA_ERROR:
                            sa.sentry.breadcrumb("Fatal media error encountered, trying to recover.", Sentry.Severity.Error, this.breadcrumbCategory, data);
                            console.error('Media error encountered; trying to recover.')
                            this.hls.recoverMediaError();
                            this.pause();
                            break;
                        default:
                            // cannot recover
                            sa.sentry.breadcrumb("HLS can't recover; destroying hls and reloading page.", Sentry.Severity.Error, this.breadcrumbCategory, data);
                            this.hls.destroy();
                            this.reloadPlayer();
                        break;
                    }
                } else {
                    sa.sentry.breadcrumb('Non-fatal hls error', Sentry.Severity.Warning, this.breadcrumbCategory, data);
                }
            });

            var manifestTimeoutDisplayDuration = 1000;
            this.manifestLoadingTimeOut = setTimeout(() => {
                console.warn('Manifest load is taking over ' + manifestTimeoutDisplayDuration + 'ms; removing autoplay classes.');
                this.playerContainer.removeClass('autoplaying playing').addClass('waiting loading');
            }, manifestTimeoutDisplayDuration);
        }
        // hls.js is not supported on platforms that do not have Media Source Extensions (MSE) enabled.
        // When the browser has built-in HLS support (check using `canPlayType`), we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element through the `src` property.
        // This is using the built-in support of the plain video element, without using hls.js.
        // Note: it would be more normal to wait on the 'canplay' event below however on Safari (where you are most likely to find built-in HLS support) the video.src URL must be on the user-driven
        // white-list before a 'canplay' event will be emitted; the last video event that can be reliably listened-for when the URL is not on the white-list is 'loadedmetadata'.
        else if (nativeHls) {
            this.media.src = this.m3u8;
            this.noHlsQualities = [{
                name: gettext('Auto'),
                audio: false,
                auto: true,
                height: 1080,
                url: this.m3u8,
            }];
            if(this.audiom3u8){
                this.noHlsQualities.push({
                    name: gettext('Audio Only'),
                    audio: true,
                    height: 0,
                    url: this.audiom3u8,
                });
            }
            this.noHlsQualities = this.sortLevelsAndCheckNames(this.noHlsQualities);
            // use this once preloading is back in place
            // $(this.media).on('loadedmetadata.init loadeddata.init', (event) => {
            //     $(this.media).off('.init');
            //     this.videoInitialized();
            // });

            this.videoInitialized();

            /*  THIS IS HOW WE USED TO GET INDIVIDUAL QUALITIES ON iOS
                USE IT IF WE NEED/WANT THEM LATER */
            // var xhr = new XMLHttpRequest();
            // $.ajax({
            //     url: this.m3u8,
            //     type: 'get',
            //     xhr: () => {
            //          return xhr;
            //     },
            //     success: (data) => {
            //         var core = xhr.responseURL.substring(0, xhr.responseURL.lastIndexOf('/'));
            //         var p = new m3u8Parser.Parser();
            //         p.push(data);
            //         p.end();
            //         if(p.manifest.playlists.length) this.noHlsQualities = [];
            //         for(var i = 0; i < p.manifest.playlists.length; i++){
            //             var l = p.manifest.playlists[i];
            //             // webcasts have relative urls and archive has absolute urls
            //             var url = l.uri.indexOf('http') != -1 ? l.uri : core + '/' + l.uri;
            //             this.noHlsQualities.push({
            //                 name: l.attributes.RESOLUTION ? l.attributes.RESOLUTION.height + 'p' : gettext('Audio Only'),
            //                 audio: l.attributes.RESOLUTION ? false : true,
            //                 bitrate: l.attributes.BANDWIDTH,
            //                 displayBitrate: this.toReadableBitrate(l.attributes.BANDWIDTH),
            //                 height: l.attributes.RESOLUTION ? l.attributes.RESOLUTION.height : 0,
            //                 url: url,
            //             });
            //         }
            //         this.noHlsQualities = this.sortLevelsAndCheckNames(this.noHlsQualities);
            //         // use this once preloading is back in place
            //         // $(this.media).on('loadedmetadata.init loadeddata.init', (event) => {
            //         //     $(this.media).off('.init');
            //         //     this.videoInitialized();
            //         // });
            //
            //         this.videoInitialized();
            //     },
            // });
        }
    }
    videoInitialized(){
        this.setupQualities();
        this.setInitialVolume();

        this.setupShortcuts();
        this.setupEvents();

        this.checkAutoplay({
            success: () => {
                setTimeout(() => {
                    this.play();
                    this.setControlsOpen();
                }, 0);
            },
            failure: () => {
                this.get('').removeClass('autoplaying playing waiting').addClass('unplayed');
            },
            any: () => {
                this.initialized = true;
            }
        });
    }
    setupIosQualities(){
        if(!this.noHlsQualities){
            this.get('.media-settings').remove();
            return;
        }

        for(var i = 0; i < this.noHlsQualities.length; i++){
            var q = this.noHlsQualities[i];
            var li = this.createQualityLi(q.name, q.url, q.audio);
            this.noHlsQualities[i].$element = li;
            if(!this.get('.quality-options li.current').length && !q.audio){
                li.addClass('current');
                this.media.src = q.url;
            }
        }
        this.get('.quality-options li').saClick((event, element) => {
            this.get('.quality-options li').removeClass('current');
            $(element).addClass('current');
            var playing = this.isPlaying();
            var src = $(element).data('src');
            var time = this.currentTime();
            this.pause();
            this.setPlayerProperty('src', src);
            this.playerContainer.toggleClass('audio-mode', $(element).hasClass('audio') ? true : false);
            this.closeSettings();
            this.qualitySwitchingCallback = () => {
                this.currentTime(time);
            };
            if(!playing) return;
            setTimeout(() => {
                this.playerContainer.addClass('waiting');
                this.play();
            }, 1);
        });
        this.setDefaultQuality();
    }
    setupHlsQualities(){
        // https://github.com/video-dev/hls.js/blob/master/docs/API.md#quality-switch-control-api
        // other optional events we could use
        // Hls.Events.LEVEL_LOADED
        // Hls.Events.LEVEL_SWITCHING
        this.hls.on(Hls.Events.LEVEL_SWITCHED, (event, info) => this.hlsLevelUpdatedEvent(event, info));
        this.hls.on(Hls.Events.LEVEL_UPDATED, (event, info) => this.hlsLevelUpdatedEvent(event, info));

        if(this.qualities) return;
        this.qualities = [{
            name: gettext('Auto'),
            level: -1,
            auto: true,
            onClick: () => {
                this.checkAudioModeHls();
                if(this.hls.autoLevelEnabled) return;
                this.hls.nextLevel = -1;
                this.hlsLevelUpdatedEvent();
            },
        }];

        var levels = [];
        for(var i = 0; i < this.hls.levels.length; i++){
            var l = this.hls.levels[i];
            // this keeps h265 from entering our quality selector
            // remove this once hls.js adds HEVC support or we remove h265 from
            // our playlists
            if(l.videoCodec && l.videoCodec.indexOf('hvc') != -1){
                this.hls.autoLevelCapping = i -1;
                continue;
            }
            levels.push({
                height: l.height,
                name: l.height + 'p',
                audio: false,
                bitrate: l.bitrate,
                displayBitrate: this.toReadableBitrate(l.bitrate),
                level: i,
                onClick: (index) => {
                    // video function
                    this.checkAudioModeHls();
                    var newQuality = this.qualities[index];
                    var newLevel = newQuality.level;
                    var current = this.hls.currentLevel;
                    var newLevelIsLower = newQuality.height < this.getHlsQualityByLevel(current).height;
                    if(current == newLevel || newLevelIsLower){
                        this.hls.loadLevel = this.qualities[index].level;
                        this.hlsLevelUpdatedEvent();
                    } else {
                        this.hls.currentLevel = this.qualities[index].level;
                    }
                }
            });
        }
        if(this.audiom3u8){
            levels.push({
                height: 0,
                name: gettext('Audio Only'),
                audio: true,
                onClick: () => {
                    // audio only function
                    if(this.audioMode) return;
                    this.playerContainer.removeClass('playing').addClass('paused waiting');
                    this.audioMode = true;
                    this.hls.destroy();
                    this.initializeHLS(this.audiom3u8);
                    setTimeout(() => {
                        this.play();
                    }, 1);
                },
            });
        }
        levels = this.sortLevelsAndCheckNames(levels);
        this.qualities = this.qualities.concat(levels);
        for(var i = 0; i < this.qualities.length; i++){
            var q = this.qualities[i];
            var li = this.createQualityLi(q.name, i, !q.height && !q.auto);
            this.qualities[i].$element = li;
            li.saClick((event, element) => {
                this.setPoster();
                var index = $(element).data('src');
                if($(element).hasClass('current') && !this.hls.autoLevelEnabled) return;
                var playing = this.isPlaying();
                this.qualities[index].onClick(index);
                this.closeSettings();
                if(!playing) return;
                setTimeout(() => {
                    this.playerContainer.addClass('waiting');
                    this.playerContainer.trigger('quality-changed');
                }, 1);
            });
        }
        this.setDefaultQuality();
    }
    setDefaultQuality(){
        // options are auto, high, low, audio
        if (this.defaultQuality == 'auto' || !this.defaultQuality) return;
        this.switchQualityByName(this.defaultQuality);
    }
    switchQualityByName(qualityString, force){
        // options are auto, high, low, audio
        var qualities = this.qualities || this.noHlsQualities;
        for(var i = 0; i < qualities.length; i++){
            var level = qualities[i];
            if(!level[qualityString]) continue;
            if(this.hls && level.level && !force){
                this.hls.nextLevel = level.level ? level.level : 0;
            } else {
                level.$element.trigger('click');
            }
        }
    }
    sortLevelsAndCheckNames(levels){
        for(var i = 0; i < levels.length; i++){
            var level = levels[i];
            for(var x = 0; x < levels.length; x++){
                if (x == i) continue;
                var comparedLevel = levels[x];
                if(level.height == comparedLevel.height){
                    level.name = level.name + ',<em class="bitrate">' + level.displayBitrate + '</em>';
                }
            }
        }
        levels.sort((a, b) => ((a.height < b.height) || (a.height == b.height && a.bitrate < b.bitrate)) ? 1 : -1);
        levels[0].high = true;
        var lowest = 0;
        for(var i = 0; i < levels.length; i++){
            if(levels[i].audio) continue;
            lowest = i;
        }
        levels[lowest].low = true;
        return levels;
    }
    checkAudioModeHls(){
        if(!this.audioMode) return;
        this.audioMode = false;
        this.playerContainer.removeClass('playing');
        this.initializeHLS();
        setTimeout(() => {
            this.play();
        }, 1);
    }
    setupQualities(){
        this.prepareQualitySelector();
        // instances where hls is not available, for instance on iPhones
        if(!this.hls){
            this.setupIosQualities();
        } else {
            this.setupHlsQualities();
        }
        $('.submenu-quality').trigger('updateHeight');
    }
    hlsLevelUpdatedEvent(event){
        // in case we need to differentiate between these
        // if(event == 'hlsLevelUpdated'){
        // } else if (event == 'hlsLevelSwitched'){
        // }
        var auto = this.getHlsQualityByLevel(-1);
        var actualLevel = this.audioMode ? this.getHlsAudioQuality() : this.getHlsQualityByLevel(this.hls.autoLevelEnabled ? this.hls.currentLevel : this.hls.nextLoadLevel);
        this.get('.quality-options li').removeClass('current');
        auto.$element.find('span').html(gettext('Auto'));

        if(this.hls.autoLevelEnabled && !this.audioMode){
            auto.$element.addClass('current');
            if(this.hls.currentLevel != -1){
                auto.$element.find('span').html(auto.$element.find('span').text() + ' <em>(' + actualLevel.name + ')</em>');
            }
        } else {
            actualLevel.$element.addClass('current');
        }
        this.playerContainer.toggleClass('audio-mode', actualLevel.audio ? true : false);

        var currentLevelName = this.get('.quality-options .current span').html();
        this.get('.current-quality-label').html(currentLevelName);
        sa.sentry.breadcrumb('Level Loaded/Switched', Sentry.Severity.Info, this.breadcrumbCategory);
    }
    prepareQualitySelector(){
        var qualityOptions = this.get('.quality-options');
        if(!this.checkmarkIcon){
            this.checkmarkIcon = qualityOptions.html();
        }
        qualityOptions.html('');
    }
    createQualityLi(name, src, audio){
        var a = audio ? 'class="audio" ': '';
        var li = $('<li ' + a + 'data-src="' + src + '"><span>' + name + '</span> ' + this.checkmarkIcon + '</li>');
        li.appendTo(this.get('.quality-options'));
        return li;
    }
    getHlsQualityByLevel(level){
        for(var i = 0; i < this.qualities.length; i++){
            if(this.qualities[i].level == level) return this.qualities[i];
        }
        return null;
    }
    getHlsAudioQuality(){
        for(var i = 0; i < this.qualities.length; i++){
            if(this.qualities[i].audio) return this.qualities[i];
        }
    }
    closeShortcutHandler(){
        super.closeShortcutHandler();
        if(this.isSettingsOpen()){
            if(this.get('.menu-settings').hasClass('open')){
                this.closeSettings();
            } else {
                this.switchSettingsMenus('.menu');
            }
        } else {
            this.exitFullscreen();
        }
    }
    toggleShortcutList(event){
        if(this.get('.submenu-shortcuts').hasClass('open')){
            this.closeSettings();
        } else {
            this.setControlsOpen();
            this.setLastMouseMovement(event);
            this.openSettings('.submenu-shortcuts');
        }
    }
    remove(){
        if(this.hls){
            this.hls.destroy();
        }
        super.remove();
    }
    pause(){
        if(!this.hasPlayed) return;
        super.pause();
        this.setControlsOpen();
    }
    play(){
        super.play(() => {
            if(!this.hasPlayed){
                this.setControlsOpen(true);
                if(this.noHlsQualities && this.isLiveStream()){
                    this.goToLive();
                }
            }
        });
    }
    isFullscreen(){
        var fs = document.fullscreenElement || document.webkitFullscreenElement
        || document.mozFullScreenElement || document.msFullscreenElement;
        return fs ? true : false;
    }
    fullscreenEnabled(){
        var enabled =   document.fullscreenEnabled ||
                    	document.webkitFullscreenEnabled ||
                    	document.mozFullScreenEnabled ||
                    	document.msFullscreenEnabled;
        return enabled ? true : false;
    }
    toggleFullscreen(){
        if(this.isFullscreen()){
            this.exitFullscreen();
        } else {
            this.requestFullscreen();
        }
    }
    exitFullscreen(){
        if(!this.isFullscreen()) return;
        if (document.exitFullscreen) {
        	document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
        	document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
        	document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
        	document.msExitFullscreen();
        }
    }
    requestFullscreen(){
        if(this.isFullscreen()) return;
        if(this.fullscreenEnabled()){
            var v = this.playerContainer[0];
            if (v.requestFullscreen) {
            	v.requestFullscreen();
            } else if (v.webkitRequestFullscreen) {
            	v.webkitRequestFullscreen();
            } else if (v.mozRequestFullScreen) {
            	v.mozRequestFullScreen();
            } else if (v.msRequestFullscreen) {
            	v.msRequestFullscreen();
            }
            this.playerContainer.focus();
        } else {
            this.setFullscreenIos();
        }
    }
    setFullscreenIos(exitFullscreen){
        var playing = this.isPlaying();
        if(playing) this.pause();
        if(!exitFullscreen){
            this.media.removeAttribute('playsinline');
            this.media.addEventListener('webkitendfullscreen', (event) => {
                this.setFullscreenIos(true);
            });
        } else {
            this.media.setAttribute('playsinline', '');
            this.media.removeAttribute('controls');
        }
        if(!playing) return;
        if(exitFullscreen){
            // we have to wait for the de-fullscreen animation to end
            setTimeout(() => {
                this.play();
            }, 400);
        } else {
            this.play();
        }
    }
    setPoster(){
        if(this.audioMode) return;
        var poster = this.currentFrameToURL();
        if(!poster || poster.length < 10) return;
        this.get('.poster').css('background-image', 'url(' + poster + ')');
    }
    currentFrameToURL(){
        var canvas = document.createElement('canvas');
        canvas.crossOrigin = 'anonymous';
        this.media.crossOrigin = 'anonymous';
        canvas.height = this.media.videoHeight;
        canvas.width = this.media.videoWidth;
        var ctx = canvas.getContext('2d');
        try{
            ctx.drawImage(this.media, 0, 0, canvas.width, canvas.height);
            return canvas.toDataURL();
        } catch (error){
            return null;
        }
    }
    isSettingsOpen(){
        return this.playerContainer.hasClass('settings-open');
    }
    toggleSettings(){
        if(this.isSettingsOpen()){
            this.closeSettings();
        } else {
            this.openSettings();
        }
    }
    openSettings(startingMenu){
        if(this.playerContainer.hasClass('settings-open')) return;
        if(this.playerContainer.data('animating-settings')) return;
        this.playerContainer.addClass('settings-open');
        startingMenu = startingMenu || '.menu';
        var menu = this.get(startingMenu);
        this.resetSettingsMenu('');
        var height = menu.data('height').clamp(0, this.getMaxSettingsHeight());
        menu.addClass('open noAnimate').height(height);
        this.playerContainer.data('animating-settings', true);
        this.playerContainer.one(sa.animationPrefixes, () => {
            menu.removeClass('noAnimate');
            this.playerContainer.data('animating-settings', false);
        });
    }
    closeSettings(){
        if(this.playerContainer.data('animating-settings')) return;
        this.playerContainer.removeClass('settings-open');
        this.playerContainer.data('animating-settings', true);
        this.playerContainer.one(sa.animationPrefixes, () => {
            if(!this.playerContainer.data('animating-settings')) return;
            this.resetSettingsMenu('');
            this.playerContainer.data('animating-settings', false);
        });
    }
    getMaxSettingsHeight(){
        return this.playerContainer.getRealDimensions().outerHeight - this.get('.video-player-controls').getRealDimensions().outerHeight;
    }
    setSettingsMaxHeight(){
        this.get('.video-settings').css('max-height', this.getMaxSettingsHeight());
    }
    resetSettingsMenu(menuToOpen){
        var menus = this.get('.menu').add(this.get('.submenu')).not(menuToOpen);
        menus.addClass('animating').removeClass('open').height(0);
        this.setSettingsMaxHeight();
        menus.one(sa.animationPrefixes, () => {
            menus.removeClass('animating');
        });
    }
    switchSettingsMenus(menuToOpen){
        var menu = this.get(menuToOpen);
        this.resetSettingsMenu(menuToOpen);
        var height = menu.data('height').clamp(0, this.getMaxSettingsHeight());
        menu.addClass('open animating').height(height);
        menu.one(sa.animationPrefixes, () => {
            menu.removeClass('animating');
        });
    }
    setupMouseRestPoller(){
        var mouseRestPollerFrequency = 500; //ms
        setInterval(() => this.handleMouseRest(), mouseRestPollerFrequency);
    }
    handleMouseRest(){
        if(!this.isMouseRestThresholdPassed()) return;
        this.setControlsClosed();
    }
    mouseEnterPlayer(event){
        this.mouseInPlayer = true;
        this.setControlsOpen();
        this.setLastMouseMovement(event);
    }
    mouseExitPlayer(event){
        this.mouseInPlayer = false;
        this.setControlsClosed();
        this.setLastMouseMovement(event);
    }
    isMouseRestThresholdPassed(){
        if(!this.lastMove) return false;
        return $.now() - this.lastMove.time >= this.mouseRestThreshold;
    }
    mouseMove(event){
        this.setControlsOpen();
        this.setLastMouseMovement(event);
    }
    setLastMouseMovement(event){
        this.mouseInPlayer = true;
        this.lastMove = {
            element: event.target,
            time: $.now(),
        };
    }
    isMouseOverControls(){
        if(!this.mouseInPlayer || sa.mobile) return 0;
        // if our mouse is over a controls top element, we don't care.
        // these instances are already covered by isTimeInputOpen() and isSettingsOpen()
        var inControlsTop = this.get('.controls-top').find(this.lastMove.element).length;
        if(inControlsTop) return 0;
        return this.get('.video-player-controls').find(this.lastMove.element).length;
    }
    isControlsOpen(){
        return this.playerContainer.hasClass('controls-open');
    }
    setControlsOpen(force){
        if(!this.hasPlayed && !force) return;
        if(this.isControlsOpen()) return;
        this.playerContainer.addClass('controls-open');
        this.animateWaveformIn();
    }
    setControlsClosed(){
        if(!this.isControlsOpen()) return;
        if(!this.isPlaying()) return;
        if(this.isSettingsOpen()) return;
        if(this.isTimeInputOpen()) return;
        if(this.isMouseOverControls()) return;
        if(this.casting) return;
        this.playerContainer.removeClass('controls-open');
        this.animateWaveformOut();
    }
    animateWaveformIn(){
        if(this.webcast) return;
        var animationInLength = 300; //ms
        this.beginAnimatingWaveform(false, animationInLength);
    }
    animateWaveformOut(){
        if(this.webcast) return;
        var animationOutLength = 200; //ms
        this.beginAnimatingWaveform(true, animationOutLength);
    }
    beginAnimatingWaveform(animateOut, animationLength){
        this.get('.sa_wave_mask').addClass('animating');
        var details = {
            startTime: 0,
            timestamp: 0,
            out: animateOut,
            length: animationLength,
            runTime: function() {
                return this.timestamp - this.startTime;
            },
            percentage: function(){
                return (this.runTime()/this.length).clamp(0,1);
            },
            completed: function(){
                return this.percentage() >= 1;
            },
        }
        this.runWaveformAnimation(details);
    }
    waveformAnimationCompletedEvent(){
        this.get('.sa_wave_mask').removeClass('animating');
        this.get('.sa_wave-container').removeClass('animatingOut');
    }
    runWaveformAnimation(details){
        var percentage = details.percentage();
        this.waveform.redrawVideo(percentage, details.out);
        if(!details.completed()){
            this.animationFrame((timestamp) => {
                if(!details.startTime) details.startTime = timestamp;
                details.timestamp = timestamp;
                this.runWaveformAnimation(details);
            });
        } else {
            this.waveformAnimationCompletedEvent();
        }
    }
    isLiveStream(){
        var liveHls = this.hls && this.hls.currentLevel != -1 && this.hls.levels[this.hls.currentLevel].details && this.hls.levels[this.hls.currentLevel].details.live;
        if(liveHls) return true;
        return super.isLiveStream();
    }
    goalLiveTime(){
        if(!this.hls || !this.hls.liveSyncPosition) return super.goalLiveTime();
        return this.hls.liveSyncPosition;
    }
    updateWaveformHoverTimeIndicator(event){
        // if we use the above, remove this line
        this.get('.time-controller .hover.time span').text(sa.toHHMMSS(this.getMouseTime(event)));

        var sliderSideBuffer = 120;
        var timeContainer = this.get('.time-controller .hover.time');
        var position = this.getMousePosition(event);
        var width = this.get('.sa_wave-container').width();
        if(position < sliderSideBuffer){
            timeContainer.css('left', sliderSideBuffer);
        } else if (position > width - sliderSideBuffer){
            timeContainer.css('left', width - sliderSideBuffer);
        } else {
            timeContainer.css('left', position);
        }
    }
    waveformMouseEnter(event){
        super.waveformMouseEnter(event);
        this.get('.time-controller .hover.time').addClass('show');
        this.updateWaveformHoverTimeIndicator(event);
    }
    waveformMouseMove(event){
        super.waveformMouseMove(event);
        this.updateWaveformHoverTimeIndicator(event);
    }
    waveformMouseExit(event){
        super.waveformMouseExit(event);
        this.get('.time-controller .hover.time').removeClass('show');
    }
    castStatusChanged(deviceName){
        super.castStatusChanged(deviceName);
        if(!deviceName) return;
        this.setControlsOpen(true);
        if(this.isLiveStream()){
            this.setPoster();
        }
    }
}
