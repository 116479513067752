(function() {
    this.saSermonList = function(settings) {
        var _this = this;
        this.backgroundColor = '';
        this.sermonRequest = null;
        this.poppedOut = false;
        this.init = function () {
            _this.sourceID = settings.sourceID;
            _this.currentPage = settings.currentPage;
            _this.pageSize = settings.pageSize;
            _this.pageCount = settings.pageCount;
            _this.errorMsg = settings.errorMsg;
            _this.titleBase = settings.titleBase;
            _this.sort = settings.sort;
            _this.query = $('.search-input.sermon-search').val();
            _this.setupEvents();
            if(_this.backgroundColor !== ''){
                $('body').css('background-color', _this.backgroundColor);
            }
            sa.audio.subscribeToInit(function(id){
                if(_this.getListing(id).hasClass('open') && !_this.poppedOut && !sa.mobile){
                    sa.audio.playId(id);
                }
            });
        };
        this.updatePage = function(){
            if($('body').hasClass('search')){
                document.title = _this.query + _this.titleBase;
                history.pushState(null, null, "?q=" + _this.query);
            }
        };
        this.getNewData = function(){
            $('.sermon-count').addClass('visible');
            var canShowSermons = false;
            if($('.sermon').length){
                $('.sermon').one(sa.animationPrefixes, function() {
                    canShowSermons = true;
                });
                $('.sermon').addClass('remove');
            } else {
                canShowSermons = true;
            }
            var path;
            if(_this.category && _this.filter && _this.category != 'all'){
                path = sa.urlHelpers.changePath(_this.sourceID, [_this.category, _this.filter]);
            } else {
                path = sa.urlHelpers.getUrlSansParameters();
            }

            var params = sa.urlHelpers.getNewParameterString({
                slim: 'true',
                q: _this.query,
                sort: _this.sort,
                page_size: _this.pageSize,
                page: _this.currentPage,
            });

            if(_this.sermonRequest != null){
                _this.sermonRequest.abort();
                _this.sermonRequest = null;
            }
            _this.sermonRequest = $.ajax({
                dataType: 'json',
                url: path + params,
                success: function(data) {
                    var count = 0;
                    function show (){
                        count += 1;
                        if(canShowSermons || count > 15){
                            _this.showNewSermons(data);
                        } else {
                            setTimeout(show, 50);
                        }
                    }
                    show();
                },
                error: function (error){
                    console.log(error);
                    $('.sermons').prepend('<h2>' + _this.errorMsg + '</h2>');
                    $('.search-input.searching').removeClass('searching');
                }
            });
        };
        this.showNewSermons = function(data){
            $('.sermons').replaceWith(data.sermons);
            $('.sermon-count').replaceWith(data.sermonCount);
            $('.footer').replaceWith(data.footer);
            $('.filter-inline-sermon-count').html('(' + $('.sermon-count').data('count') + ')');
            sa.audio.clearRemovedPlayers();
            sa.popups.default();
            sa.checkScrollbars();
            _this.bindSermonPlayButtons();
            _this.bindPageButtons();
            $('.search-input.searching').removeClass('searching');
            _this.updatePage();
            sa.aria.outline.update();
        };
        this.getScrollTop = function(container, item){
            var offset = container.offset().top;
            var itemPos = {
                top: item.offset().top - offset,
                bottom: item.offset().top + item.outerHeight() - offset
            };
            var containerPos = {
                top: 0,
                bottom: container.outerHeight()
            };
            if(itemPos.top >= containerPos.top && itemPos.bottom <= containerPos.bottom){
                // just right
                return null;
            } else if (itemPos.top < containerPos.top) {
                // too high
                return container.scrollTop() + itemPos.top;
            } else {
                // too low
                return container.scrollTop() + (itemPos.bottom - containerPos.bottom);
            }

        };
        this.goToPage = function(page){
            _this.currentPage = page;
            _this.getNewData();
        };
        this.setActivePage = function(element){
            $('[data-page-button]').each(function(index){
                $(this).removeClass('active');
            });
            element.addClass('active');
        };
        this.bindPageButtons = function(){
            var page = _this.currentPage;
            $('.footer .skips button').each(function(){
                $(this).prop('disabled', false);
            });
            $('[data-page-button]').each(function(index){
                var number = $( this ).data('page-button');
                if(number != page){
                    $(this).saClick(function(){
                        _this.setActivePage($(this));
                        _this.goToPage(number);
                    });
                } else {
                    $(this).addClass('active');
                }

            });

            if(page > 1){
                $('.first-page').saClick(function(){
                    _this.goToPage(1);
                });
                $('.previous-page').saClick(function(){
                    _this.goToPage(page - 1);
                });
            } else {
                $('.first-page').prop('disabled', true);
                $('.previous-page').prop('disabled', true);
            }
            if(page < _this.pageCount){
                $('.next-page').saClick(function(){
                    _this.goToPage(page + 1);
                });
                $('.last-page').saClick(function(){
                    _this.goToPage(_this.pageCount);
                });
            } else {
                $('.next-page').prop('disabled', true);
                $('.last-page').prop('disabled', true);
            }
        };
        this.updateCount = function(count){
            _this.pageCount = count;
        };
        this.getListing = function(id){
            return $('#' + id + '-listing');
        };
        this.bindSermonPlayButtons = function(){
            $('.popup-audio').saClick(function(){
                _this.poppedOut = true;
            });
            $('[data-toggle-for]').each(function(index){
                $(this).saClick(function(event){
                    var sermonID = $( this ).data('toggle-for');
                    var parent = $('#' + sermonID + '-listing');
                    var container = $('.sermons');
                    var sAudio = sa.audio.getPlayerById(sermonID);
                    if(parent.hasClass('open')){
                        parent.removeClass('open');
                        sa.audio.pauseAll();
                        if(sAudio && sAudio.focus){
                            sAudio.focus(false);
                        }
                        parent.one(sa.animationPrefixes, function() {
                            sa.checkScrollbars();
                        });
                        if(parent.focus){
                            parent.focus();
                        }
                        if(event.type == 'click'){
                            parent.removeClass('outline');
                        }
                    } else {
                        $('.sermon').each(function(){
                            $(this).removeClass('open');
                        });
                        parent.addClass('open');
                        parent.one(sa.animationPrefixes, function() {
                            sa.checkScrollbars();
                            var scrollT = _this.getScrollTop( container, parent );
                            if(scrollT != null){
                                container.animate ({
                                    scrollTop: scrollT
                                }, 500, 'swing',  function(){
                                    sa.checkScrollbars();
                                });
                            }
                        });
                        _this.poppedOut = false;
                        if(sAudio){
                            sa.audio.playId(sermonID);
                        } else {
                            sa.audio.pauseAll();
                            $.ajax({
                                dataType: 'html',
                                url: '/player/a/' + sermonID + '/?slim=true',
                                success: function(data) {
                                    var playerContainer = $('#' + sermonID + '-listing .player-container');
                                    playerContainer.addClass('loaded');
                                    playerContainer.html(data);
                                    // this appears to be doing more harm than good. We just set autoplay on the html
                                    // and now it works fine
                                    // if(parent.hasClass('open') && !_this.poppedOut && !sa.mobile){
                                    //     sa.audio.playId(sermonID);
                                    //     playerContainer.find('.audio-player').addClass('autoplays');
                                    // }
                                    sa.dynamicContent();
                                }
                            });
                        }
                    }
                });
            });
        };
        this.search = function(){
            _this.query = $('.search-input.sermon-search').val();
            _this.currentPage = 1;
            $('.filter-options-dropdown, .browser-body-cover').addClass('hide');
            _this.getNewData();
        };
        this.setupEvents = function(){
            $(window).on('resize', function(){
                sa.checkScrollbars();
            });
            sa.search.subscribe($('.search-input.sermon-search'), _this.search);
            $('.close-filter-settings, .browser-body-cover').saClick(function(){
                $('.filter-options-dropdown, .browser-body-cover').addClass('hide');
            });
            $('.open-filter-settings').saClick(function(){
                $('.filter-options-dropdown, .browser-body-cover').removeClass('hide');
            });
            $('.dropdown').on('change', function(){
                _this.currentPage = 1;
                _this.category = sa.dropdownValue('filter-dropdown-category');
                _this.filter = sa.dropdownValue('filter-dropdown-' + _this.category);
                _this.sort = sa.dropdownValue('sermon-sort');
                _this.getNewData();
            });
            sa.webcasts.subscribeToWebcastCheck( function(){
                var notification = $('.broadcaster-online-notification');
                var online = sa.webcasts.getWebcastsInProgressBySource(_this.sourceID);
                var visible = notification.hasClass('online');
                if(online){
                    if(!visible) notification.addClass('online');
                } else {
                    if(visible) notification.removeClass('online');
                }
            });
            _this.bindPageButtons();
            _this.bindSermonPlayButtons();
        };
        _this.init();
    };
}());
