(function() {
    this.saWaveform = function(settings) {
        var _this = this;
        this.waves = {
            details: [{
                class: 'background',
                color: 'rgba(120,120,120,1)',
                videoColor: 'rgba(255,255,255,.2)',
                reflectionColor: 'rgba(120,120,120,.2)',
                darkReflectionColor: 'rgba(120,120,120,.3)',
            }, {
                class: 'buffered',
                color: 'rgba(120,120,120,1)', // unused
                videoColor: 'rgba(255,255,255,.3)',
                reflectionColor: 'rgba(120,120,120,.2)' //unused
            }, {
                class: 'played',
                color: 'rgba(0,147,255,1)',
                reflectionColor: 'rgba(183,220,242,1)',
                darkReflectionColor: 'rgba(0,57,103,1)'
            }, {
                class: 'scrub',
                color: 'rgba(255,255,255, .5)',
                darkColor: 'rgba(255,255,255, .3)',
                reflectionColor: 'rgba(0,0,0,0)'
            }]
        };
        this.size = {
            height: 60,
            width: 1800,
        };
        this.resizing = null;
        this.initialized = false;
        this.settings = {
            barGap: 1,
            barWidth: 2,
            minPeakHeight: 0.05,
            reflectionHeight: 0.33,
            resizeDelay: 75
        };
        this.doubledSettings = {
            barGap: 2,
            barWidth: 4,
        };
        this.videoSettings = {
            reflectionHeight: 0,
            barGap: 0,
            barGapDefault: 0,
            barGapHover: 2,
            barWidth: 4,
            barWidthDefault: 4,
            barWidthHover: 2,
            minPeakHeight: 1,
            minPeakHeightDefault: 1,
            minPeakHeightHover: 0.25,
        };
        this.init = function () {
            sa.sentry.breadcrumb('Starting waveform initialization.', Sentry.Severity.Info, 'waveform');
            _this.id = settings.id;
            _this.resizeEvent = 'resize.' + _this.id + ', orientationchange.' + _this.id;
            if(settings.peaks && !settings.simple){
                _this.waves.peaks = settings.peaks;
            } else {
                _this.waves.peaks = [];
                for(var i = 0; i < 2248; i++){
                    _this.waves.peaks.push(1);
                }
            }
            _this.updateSettings();

            for(var i = 0; i < _this.waves.details.length; i++){
                _this.canvas.init(i);
            }
            $(window).on(_this.resizeEvent, function () {
                clearTimeout(_this.resizing);
                _this.resizing = setTimeout(_this.resize, _this.settings.resizeDelay);
            });
            _this.success();
            sa.sentry.breadcrumb('Finished waveform initialization.', Sentry.Severity.Info, 'waveform');
        };
        this.canvas = {
            getSize: function(){
                var realDimensions = $(_this.id).getRealDimensions();
                _this.size.width = Math.round(realDimensions.width);
                _this.size.height = Math.round(realDimensions.height);
            },
            getContext: function(index){
                return $(_this.id + ' .wave_' + _this.waves.details[index].class + ' canvas')[0].getContext('2d');
            },
            create: function(index){
                $(document.createElement('canvas'))
                .appendTo(_this.id)
                .addClass('sa_wave_canvas')
                .wrap( '<div class="sa_wave_mask wave_' + _this.waves.details[index].class + '"></div>' );
            },
            init: function(index){
                _this.canvas.create(index);
                _this.canvas.getSize();
                _this.canvas.drawWaveform(index);
            },
            getFloats: function (){
                if(_this.waves.floats && _this.waves.floats.length > 0){
                    return _this.waves.floats;
                }
                var origFloats = _this.waves.peaks;
                var min = _this.settings.minPeakHeight;
                var rowHighest = min;
                var bar = _this.settings.barWidth + _this.settings.barGap;
                var segments = Math.ceil(_this.size.width/bar);
                var conv = origFloats.length/(_this.size.width/bar);
                var newFloats = [];
                for(var i = 0; i < origFloats.length; i++){
                    if(i % conv < 1 && i > 0 || i == origFloats.length - 1 && newFloats.length < segments){
                        if(i == origFloats.length - 1 && rowHighest == min){
                            rowHighest = origFloats[i].clamp(min, 1);
                        }
                        newFloats.push(rowHighest);
                        rowHighest = min;
                    } else{
                        if(origFloats[i] > rowHighest){
                            rowHighest = origFloats[i];
                        }
                    }
                }
                _this.waves.floats = newFloats;
                return newFloats;
            },
            drawWaveform: function (index){
                var ctx = _this.canvas.getContext(index);
                if(ctx.canvas.width == _this.size.width && ctx.canvas.height == _this.size.height){
                    ctx.clearRect(0, 0, _this.size.width, _this.size.height);
                } else {
                    ctx.canvas.width = _this.size.width;
                    ctx.canvas.height = _this.size.height;
                }

                var details = _this.waves.details[index];
                var bar = _this.settings.barWidth + _this.settings.barGap;
                var actualBarWidth = Math.round(_this.settings.barWidth);
                //Draw Rectangles
                var floats = _this.canvas.getFloats();
                var barHeight = _this.size.height * (1 - _this.settings.reflectionHeight);
                var reflectionHeight = _this.size.height * _this.settings.reflectionHeight;
                var reflectionTop = Math.round(barHeight);
                var reflect = reflectionHeight != 0;
                ctx.fillStyle = details.color;
                for(var i = 0; i < floats.length; i++){
                    var top = Math.round((1 - floats[i]) * barHeight);
                    var bottom = Math.round(barHeight - top);
                    ctx.fillRect(i*bar,top,actualBarWidth,bottom);
                    if(!reflect) continue;
                    var reflectionBottom = Math.round(floats[i] * reflectionHeight);
                    ctx.fillStyle = details.reflectionColor;
                    ctx.fillRect(i*bar,reflectionTop,_this.settings.barWidth,reflectionBottom);
                    ctx.fillStyle = details.color;
                }
            }
        };
        this.redrawVideo = function(percentage, reverse){
            _this.canvas.getSize();
            percentage = reverse ? 1 - percentage : percentage;

            var s = _this.settings;
            var newBG = ((s.barGapHover - s.barGapDefault) * percentage) + s.barGapDefault;
            var newWidth = ((s.barWidthHover - s.barWidthDefault) * percentage) + s.barWidthDefault;
            var newPeak = ((s.minPeakHeightHover - s.minPeakHeightDefault) * percentage) + s.minPeakHeightDefault;

            _this.settings.barGap = newBG.clamp(s.barGapDefault, s.barGapHover);
            _this.settings.barWidth = newWidth.clamp(s.barWidthHover, s.barWidthDefault);
            _this.settings.minPeakHeight = newPeak.clamp(s.minPeakHeightHover, s.minPeakHeightDefault);

            _this.waves.floats = null;
            for(var i = 0; i < _this.waves.details.length; i++){
                _this.canvas.drawWaveform(i);
            }
        };
        this.resize = function (){
            if(!_this.initialized) return;
            _this.canvas.getSize();
            _this.waves.floats = null;
            for(var i = 0; i < _this.waves.details.length; i++){
                _this.canvas.drawWaveform(i);
            }
        };
        this.unbindResizeEvent = function () {
            $(window).off(_this.resizeEvent);
        };
        this.updateSettings = function () {
            if(settings.dark){
                for(var i = 0; i < _this.waves.details.length; i++){
                    var details = _this.waves.details[i];
                    if(details.darkReflectionColor){
                        details.reflectionColor = details.darkReflectionColor;
                    }
                    if(details.darkColor){
                        details.color = details.darkColor;
                    }
                }
            }
            if(settings.doubled){
                $.extend( _this.settings, _this.doubledSettings );
            }
            if(settings.video){
                $.extend( _this.settings, _this.videoSettings );
                if(settings.simple){
                    _this.settings.barGapHover = 0;
                }
                for(var i = 0; i < _this.waves.details.length; i++){
                    var details = _this.waves.details[i];
                    if(details.videoColor){
                        details.color = details.videoColor;
                    }
                }
            }
        };
        this.success = function (){
            _this.initialized = true;
            $(_this.id).addClass('loaded');
        };
        _this.init();
    };
}());
