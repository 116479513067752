// This file/class uses Babel + "ES6"

class saPlayer extends saPlayerCore {
    constructor(settings) {
        settings.type = 'audio';
        super(settings);

        this.setDuration();
        this.setupEvents();
        this.setupControls();
        this.setInitialVolume();
        this.setupShortcuts();

        this.checkAutoplay({
            success: () => {
                this.play();
            },
            any: () => {
                sa.audio.initialized(this.sermonID);
                this.initialized = true;
            },
        });
    }
}
