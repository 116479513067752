// This file/class uses Babel + "ES6"

// Chromecast
// https://developers.google.com/cast/docs/chrome_sender/integrate

// AirPlay
// https://developer.apple.com/design/human-interface-guidelines/airplay/overview/media-playback/
// https://developer.apple.com/documentation/webkitjs/adding_an_airplay_button_to_your_safari_media_controls

class saCast{
    constructor(settings){
        this.id = settings.id;
        this.type = settings.type;
        this.metadata = settings.metadata;
        this.bindBigCastButtons();
        this.setPlayer();
        this.checkInit();
    }
    get isVideo(){
        return this.type == 'video';
    }
    get(str){
        return $('#' + this.id + ' ' + str);
    }
    get canChromecast(){
        return window.chromecastAvailable ? true : false;
    }
    get canAirPlay(){
        return window.WebKitPlaybackTargetAvailabilityEvent ? true : false;
    }
    get isAirplaying(){
        return this.player.media.webkitCurrentPlaybackTargetIsWireless ? true : false;
    }
    get isChromecasting(){
        return this.currentSession ? true : false;
    }
    get castDeviceName(){
        if(this.canChromecast){
            return this.currentSession.getCastDevice().friendlyName;
        }
    }
    bindBigCastButtons(){
        $('.big-cast-button .chromecast span').saClick((event, element) => {
            event.stopImmediatePropagation();
            $('google-cast-launcher').eq(0).trigger('click');
        });
    }
    checkInit(){
        if(this.canAirPlay){
            this.player.media.addEventListener('webkitplaybacktargetavailabilitychanged', (event) => this.airPlayTargetAvailabilityChanged(event));
        } else if(this.canChromecast){
            this.initChromecast();
        } else {
            $(document).on('chromecastCheck', () => {
                this.initChromecast();
            });
        }
        this.initGeneric();
    }
    airPlayTargetAvailabilityChanged(event){
        if(event.availability != 'available') return;
        this.initAirPlay();
    }
    setPlayer(){
        var media = this.get(this.type)[0];
        var list = this.isVideo ? sa.videos : sa.players;
        for(var i = 0; i < list.length; i++){
            if(list[i].media == media) this.player = list[i];
        }
    }
    initGeneric(){
        this.player.cast = this;
        this.player.onCastSetup();
    }
    initAirPlay(){
        if(this.airPlayInitialized) return;
        this.airPlayInitialized = true;

        var airPlayButton = this.get('.cast.airplay');
        airPlayButton.addClass('available');

        airPlayButton.saClick((event) => {
            this.showAirplayPicker();
        });
        this.player.media.removeEventListener('webkitplaybacktargetavailabilitychanged', (event) => this.airPlayTargetAvailabilityChanged(event));
        this.player.media.addEventListener('webkitcurrentplaybacktargetiswirelesschanged', (event) => {
            this.statusChanged(this.isAirplaying);
        });
    }
    showAirplayPicker(){
        // this check should be able to go away once we have stats
        // this.player.autoplay = true;
        if(this.isVideo && !this.player.initialized){
            $(this.player.media).on('loadedmetadata.cast', () => {
                this.player.media.webkitShowPlaybackTargetPicker();
                $(this.player.media).off('loadedmetadata.cast');
            });
            this.player.initializeHLS();
            this.player.media.load();
        } else if (!this.isVideo && !this.hasPlayed){
            $(this.player.media).on('loadedmetadata.cast', () => {
                this.player.media.webkitShowPlaybackTargetPicker();
                $(this.player.media).off('loadedmetadata.cast');
            });
            this.player.media.load();
        } else {
            this.player.media.webkitShowPlaybackTargetPicker();
        }
        // just use the following once we have video stats
        // this.player.media.webkitShowPlaybackTargetPicker();
    }
    initChromecast(){
        if(!this.canChromecast) return;
        if(!window.cast){
            var noCastText = 'Chromecast should be available, but "cast" is not defined';
            sa.sentry.breadcrumb(noCastText, Sentry.Severity.Warning, 'Casting');
            console.warn(noCastText)
            return;
        }
        this.chromecastInstance = cast.framework.CastContext.getInstance();
        this.chromecastPlayer = new cast.framework.RemotePlayer();
        this.chromecastPlayerController = new cast.framework.RemotePlayerController(this.chromecastPlayer);
        this.chromecastInstance.addEventListener(
            cast.framework.CastContextEventType.CAST_STATE_CHANGED,
            (event) => {
                var devicesAvailable = event.castState != cast.framework.CastState.NO_DEVICES_AVAILABLE;
                this.get('.chromecast').toggleClass('available', devicesAvailable);
            }
        );
        this.chromecastInstance.addEventListener(
            cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
            (event) => {
                this.currentSession = this.chromecastInstance.getCurrentSession();
                switch (event.sessionState) {
                    case cast.framework.SessionState.SESSION_STARTED:
                    this.ccLoadMedia();
                    this.statusChanged(this.castDeviceName);
                    break;
                    case cast.framework.SessionState.SESSION_RESUMED:
                    this.ccResumeMedia();
                    break;
                    case cast.framework.SessionState.SESSION_ENDED:
                    this.statusChanged(false);
                    break;
                }
            }
        );
        this.chromecastInstance.setOptions({
            // https://cast.google.com/u/3/publish/#/applications/edit/3524DCDC
            receiverApplicationId: '3524DCDC', // '3524DCDC' // chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID
            autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
        });
        $($('google-cast-launcher')[0].shadowRoot).find('svg').addClass('icon');
    }
    ccLoadMedia(time){
        var url = this.player.m3u8 ? this.player.m3u8 : $(this.player.media).find('source').attr('src');
        var mediaInfo = new chrome.cast.media.MediaInfo(url, this.type);
        var request = new chrome.cast.media.LoadRequest(mediaInfo);
        request.currentTime = time != undefined ? time : this.player.currentTime();

        // https://developers.google.com/cast/docs/styled_receiver
        mediaInfo.metadata = new chrome.cast.media.GenericMediaMetadata();
        var img = this.metadata[this.type + 'Image'];
        mediaInfo.metadata.images = [new chrome.cast.Image(img)];
        mediaInfo.metadata.subtitle = this.metadata.subtitle;
        mediaInfo.metadata.title = this.metadata.title;
        this.currentSession.loadMedia(request).then(
            () => {
                this.setupCcEvents();
                this.goToLive();
            },
            (errorCode) => {
                this.ccLoadFailure(errorCode);
            }
        );
    }
    ccResumeMedia(){
        this.statusChanged(this.castDeviceName);
        if(this.ccCheckSameSermon()){
            this.setupCcEvents();
            return;
        }
        this.ccLoadMedia(0);
    }
    ccLoadFailure(errorCode){
        console.warn('Chromecast load failure: ' + errorCode);
        this.player.castStatusChanged(false);
    }
    ccCheckSameSermon(){
        // checks if it is the same sermon via the metadata
        if(!this.chromecastPlayer.mediaInfo) return false;
        var sessionMetadata = this.chromecastPlayer.mediaInfo.metadata;
        if(sessionMetadata.title != this.metadata.title) return false;
        if(sessionMetadata.subtitle != this.metadata.subtitle) return false;
        return true;
    }
    setupCcEvents(){
        /* cast.framework.RemotePlayerEventType list
        ANY_CHANGE: "anyChanged"
        IS_CONNECTED_CHANGED: "isConnectedChanged"
        IS_MEDIA_LOADED_CHANGED: "isMediaLoadedChanged"
        QUEUE_DATA_CHANGED: "queueDataChanged"
        VIDEO_INFO_CHANGED: "videoInfoChanged"
        DURATION_CHANGED: "durationChanged"
        CURRENT_TIME_CHANGED: "currentTimeChanged"
        IS_PAUSED_CHANGED: "isPausedChanged"
        VOLUME_LEVEL_CHANGED: "volumeLevelChanged"
        CAN_CONTROL_VOLUME_CHANGED: "canControlVolumeChanged"
        IS_MUTED_CHANGED: "isMutedChanged"
        CAN_PAUSE_CHANGED: "canPauseChanged"
        CAN_SEEK_CHANGED: "canSeekChanged"
        DISPLAY_NAME_CHANGED: "displayNameChanged"
        STATUS_TEXT_CHANGED: "statusTextChanged"
        TITLE_CHANGED: "titleChanged"
        DISPLAY_STATUS_CHANGED: "displayStatusChanged"
        MEDIA_INFO_CHANGED: "mediaInfoChanged"
        IMAGE_URL_CHANGED: "imageUrlChanged"
        PLAYER_STATE_CHANGED: "playerStateChanged"
        IS_PLAYING_BREAK_CHANGED: "isPlayingBreakChanged"
        NUMBER_BREAK_CLIPS_CHANGED: "numberBreakClipsChanged"
        CURRENT_BREAK_CLIP_NUMBER_CHANGED: "currentBreakClipNumberChanged"
        CURRENT_BREAK_TIME_CHANGED: "currentBreakTimeChanged"
        CURRENT_BREAK_CLIP_TIME_CHANGED: "currentBreakClipTimeChanged"
        BREAK_ID_CHANGED: "breakIdChanged"
        BREAK_CLIP_ID_CHANGED: "breakClipIdChanged"
        WHEN_SKIPPABLE_CHANGED: "whenSkippableChanged"
        LIVE_SEEKABLE_RANGE_CHANGED: "liveSeekableRangeCha

        // loop through all event types
        var k = Object.keys(cast.framework.RemotePlayerEventType);
        for(var i = 0; i < k.length; i++){
            var e = cast.framework.RemotePlayerEventType[k[i]];
            this.chromecastPlayerController.addEventListener(e, (event) => {
                console.log(event);
            });
        }
        */
        var events = cast.framework.RemotePlayerEventType;
        this.chromecastPlayerController.addEventListener(events.CURRENT_TIME_CHANGED, (event) => {
            this.remotePlayerTimeSet(event.value);
        });
        this.chromecastPlayerController.addEventListener(events.VOLUME_LEVEL_CHANGED, (event) => {
            this.remotePlayerVolumeSet(event.value);
        });
        this.chromecastPlayerController.addEventListener(events.IS_MUTED_CHANGED, (event) => {
            this.remotePlayerMuteToggle(event.value);
        });
        this.chromecastPlayerController.addEventListener(events.IS_PAUSED_CHANGED, (event) => {
            this.remotePlayerPauseStateToggle(event.value);
        });
        this.chromecastPlayerController.addEventListener(events.DISPLAY_STATUS_CHANGED, (event) => {
            if(event.value != '') return;
            this.mediaEnded();
        });
    }
    mediaEnded(){
        this.remotePlayerEnded();
    }
    remotePlayerTimeSet(time){
        if(!sa.validNumber(time)) return;
        if(!this.player.casting) return;
        this.player.currentTime(time);
    }
    remotePlayerVolumeSet(volume){
        this.player.setVolume(volume * 100);
    }
    remotePlayerMuteToggle(isMuted){
        if(isMuted){
            this.player.mute();
        } else {
            this.player.unmute();
        }
    }
    remotePlayerPauseStateToggle(paused){
        if(paused){
            this.player.eventPaused();
        } else {
            this.player.eventPlaying();
        }
    }
    remotePlayerEnded(){
        // we could fire the player ended event, but this could just mean
        // that casting stopped and not that the media ended
        this.stopCasting();
    }
    setVolume(volume){
        if(this.chromecastPlayer){
            if(this.chromecastPlayer.volumeLevel == volume) return;
            this.chromecastPlayer.volumeLevel = volume;
            this.chromecastPlayerController.setVolumeLevel();
        }
    }
    setCurrentTime(time){
        if(this.chromecastPlayer){
            if(this.chromecastPlayer.currentTime == time) return;
            if(!this.chromecastPlayer.canSeek) return;
            this.chromecastPlayer.currentTime = time;
            this.chromecastPlayerController.seek();
        }
    }
    toggleMute(){
        if(this.chromecastPlayerController){
            this.chromecastPlayerController.muteOrUnmute();
        }
    }
    togglePlay(){
        if(this.chromecastPlayerController){
            this.chromecastPlayerController.playOrPause();
        }
    }
    goToLive(){
        if(this.chromecastPlayer){
            if(!this.chromecastPlayer.liveSeekableRange) return;
            var endTime = this.chromecastPlayer.liveSeekableRange.end;
            this.setCurrentTime(endTime);
        }
    }
    stopCasting(){
        if(this.canChromecast && this.currentSession){
            this.currentSession.endSession(true);
        }
    }
    statusChanged(value){
        this.player.castStatusChanged(value);
    }
}
