// This file/class uses Babel + "ES6"

class saUnifiedPlayer {
    constructor(settings) {
        this.settings = settings;
        this.videoPlayer = sa.videos[0];
        this.audioPlayer = sa.players[0];
        this.setupWebcastUpdate();
        if(!this.videoPlayer && !this.audioPlayer) return;
        this.setupToggle();
        this.setupMaxVideoHeight();
        this.setupShare();
        this.activePlayer.setGlobalFocus(true);
    }
    get container(){
        return $('.unified-player');
    }
    get isSermon(){
        return this.settings.sermon_id ? true : false;
    }
    get isWebcast(){
        return this.settings.webcast ? true : false;
    }
    get audioActive(){
        return this.container.hasClass('audio-active');
    }
    get videoActive(){
        return this.container.hasClass('video-active');
    }
    get activePlayer(){
        if(this.isSermon && this.audioActive && this.audioPlayer){
            return this.audioPlayer;
        }
        return this.videoPlayer;
    }
    checkWebcast(){
        var online = sa.webcasts.getWebcastsInProgressBySource(this.settings.broadcaster_id)
        if(this.settings.offline && online){
            location.reload();
        }
        if(online.total_count){
            sa.countUp.startWithNewValues({
                parent: $('.viewer-count'),
                value: online.total_count,
                duration: 500,
            });
        }
    }
    setupWebcastUpdate(){
        if(!this.isWebcast) return;
        if(this.settings.offline){
            sa.webcasts.interval = 8;
        } else {
            var val = $('.viewer-count span').text();
            sa.countUp.setValues({
                parent: $('.viewer-count'),
                value: val,
                startingValue: val,
            });
        }
        sa.webcasts.subscribeToWebcastCheck(() => this.checkWebcast() );
    }
    setMaxVideoHeight(){
        if(!this.videoPlayer) return;
        var width = this.videoPlayer.playerContainer.width();
        var max = width * .5625;
        max.clamp(0, 600);
        $('.video-container').css('max-height', max);
    }
    setupMaxVideoHeight(){
        $(window).on('resize', () => {
            this.setMaxVideoHeight();
        });
        this.setMaxVideoHeight();
    }
    setupShare(){
        $('*').keysEvent([sa.keycodes.esc], 'keydown', function(){
            $('.share-window').removeClass('show');
        }, true);
        if(!this.isSermon) return;
        var players = [this.videoPlayer, this.audioPlayer];
        for(var i = 0; i < players.length; i++){
            if(!players[i]) continue;
            players[i].onEvent('ended', () => {
                $('.share-window').addClass('show');
            });
        }
    }
    toggleMediaType(){
        $('.tab').toggleClass('active');
        this.container.toggleClass('audio-active video-active');
    }
    setupToggle(){
        $('.tab').saClick(() => {
            var playing = this.activePlayer.isPlaying();
            this.activePlayer.setGlobalFocus(false);
            if(playing && this.isSermon){
                this.activePlayer.pause();
            }
            this.toggleMediaType();
            if (this.isWebcast){
                var quality = this.audioActive ? 'audio' : 'auto';
                this.videoPlayer.switchQualityByName(quality, true);
            }
            this.activePlayer.setGlobalFocus(true);
            if(playing){
                this.activePlayer.play();
            }
        });
        if(this.videoPlayer){
            this.videoPlayer.playerContainer.on('quality-changed', () => {
                var audio = this.videoPlayer.audioMode ? true : false;
                if(audio != this.audioActive){
                    this.toggleMediaType();
                }
            });
        }
    }
}
